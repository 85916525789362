<template>
  <HeaderFirst></HeaderFirst>
  <div class="container clearfix" style="margin-top: 15px">
    <HelpMenu class="page-col-sm"></HelpMenu>
    <div class="page-col-lg">
      <div class="npanel">
        <div class="npanel-heading">
          <h4>用户协议</h4>
        </div>
        <div class="npanel-body spage">
          <h3
            style="
              text-align: center;
              font-family: 宋体;
              font-size: 13.5pt;
              line-height: 24pt;
              background: #ffffff;
            "
            align="center"
          >
            <strong>
              <span
                style="
                  font-family: 黑体;
                  font-size: 10.5pt;
                  background-image: initial;
                  background-position: initial;
                  background-size: initial;
                  background-repeat: initial;
                  background-attachment: initial;
                  background-origin: initial;
                  background-clip: initial;
                "
                >经济运行平台用户协议</span
              >
            </strong>
          </h3>
          <p
            style="
              margin: 0pt 0pt 0.0001pt;
              text-align: right;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 18pt;
              background: #ffffff;
            "
            align="right"
          >
            <span
              style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >更新日期：&nbsp;</span
            >
            <span
              style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >2023</span
            >
            <span
              style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >年&nbsp;</span
            >
            <span
              style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >10</span
            >
            <span
              style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >月&nbsp;</span
            >
            <span
              style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >21</span
            >
            <span
              style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >日</span
            >
          </p>
          <p
            style="
              margin: 0pt 0pt 0.0001pt;
              text-align: right;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 18pt;
              background: #ffffff;
            "
            align="right"
          >
            <span
              style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >生效日期：&nbsp;</span
            >
            <span
              style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >2023</span
            >
            <span
              style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >年&nbsp;</span
            >
            <span
              style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >10</span
            >
            <span
              style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >月&nbsp;</span
            >
            <span
              style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >21</span
            >
            <span
              style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >日</span
            >
          </p>
          <p
            style="
              margin: 0pt 0pt 0.0001pt;
              text-align: right;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 18pt;
              background: #ffffff;
            "
            align="right"
          >
            &nbsp;
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">欢迎</span>
            <span style="font-family: 黑体; font-size: 10.5pt"
              >您注册经济运行平台账户并</span
            >
            <span style="font-family: 黑体; font-size: 10.5pt">使用经济运行平台</span>
            <span style="font-family: 黑体; font-size: 10.5pt"
              >的各项服务！</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >为了使您获得愉悦和安全的服务体验，在此列明</span
            ><span style="font-family: 黑体; font-size: 10.5pt">本协议</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >条款，详述了您注册及使用</span
            ><span style="font-family: 黑体; font-size: 10.5pt">经济运行平台</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >服务所须遵守的条款和条件，请您在注册</span
            ><span style="font-family: 黑体; font-size: 10.5pt">经济运行平台</span
            ><span style="font-family: 黑体; font-size: 10.5pt">账号或使用</span
            ><span style="font-family: 黑体; font-size: 10.5pt">经济运行平台</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >有关服务之前仔细阅读。</span
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >尤其</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >是以黑体加粗或加下划线显著标示的</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >规则条款，</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >该等规则</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >条款</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >可能涉及相关方的责任免除或限制、法律适用与争议解决，建议您重点阅读。</span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >本协议的签约双方为</span
            ><span style="font-family: 黑体; font-size: 10.5pt">经济运行平台的</span
            ><span style="font-family: 黑体; font-size: 10.5pt">运营商</span
            ><span style="font-family: 黑体; font-size: 10.5pt">北京易可诺信息科技有限公司与经济运行平台服务的使用人（在本协议中称呼为“您”或者“用户”），本协议是您与</span>
            <span style="font-family: 黑体; font-size: 10.5pt">经济运行平台之间关于您注册经济运行平台账户及使用经济运行平台服务所订立的条款，具有正式书面合同的效力。</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">本协议内容包括本协议正文及所有经济运行平台已经发布或将发布的具体服务条款、补充协议、各类规则条款等。所有具体服务条款、补充协议、规则为本协议不可分割的一部分，与协议正文具有同等法律效力。</span></strong>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">一、</span
            ><span style="font-family: 黑体; font-size: 10.5pt">特别说明</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt"
              >1.1经济运行平台是由</span
            ><span style="font-family: 黑体; font-size: 10.5pt">北京易可诺信息科技有限公司运营的一款</span
            ><span style="font-family: 黑体; font-size: 10.5pt">综合信息查询平台（包括但不限于经济运行平台网页端、经济运行平台小程序以及随技术发展出现的新形态等形式）。</span>
            <span style="font-family: 黑体; font-size: 10.5pt"
              >经济运行平台以非人工检索方式自动汇编已合法公开的信息数据（包括但不限于宏观经济数据、行业经济数据、微观经济数据、企业信息、上市公司财务数据、经济舆情等），</span
            ><span style="font-family: 黑体; font-size: 10.5pt">为用户免费或有偿提供</span
            ><span style="font-family: 黑体; font-size: 10.5pt">信息快速查询服务。</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">1.2经济运行平台的信息数据来源网站包括但不限于：国家统计局、经济年鉴、行业年鉴、人社部、教育部、住建部、交通部、民政部、水利部、财政部、土地市场网、海关总署</span> 
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">1.3经济运行平台将按照本协议条款及有关操作规则为您提供服务，您必须同意本协议全部条款，才能注册经济运行平台的账号或使用经济运行平台的全部服务。</span>
            <strong>
              <span style="font-family: 黑体; font-size: 10.5pt">如您不同意本协议的任意内容，您应立即停止注册或使用。如您在进行注册过程中点击“同意”即表示您已完全知悉并接受本协议，</span>
            </strong>
              <strong><span style="font-family: 黑体; font-size: 10.5pt">并自愿</span></strong>
              <strong><span style="font-family: 黑体; font-size: 10.5pt">接受本协议的所有内容的约束。</span></strong>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">1.4
              为更好地向您提供各项服务，经济运行平台有权根据服务需要制定具体规则条款，并在经济运行平台相关服务页面上予以公布。</span
            >
            <strong><span style="font-family: 黑体; font-size: 10.5pt">您在使用具体服务前，应仔细阅读具体服务所适用的服务规则，您注册经济运行平台账号并使用具体服务即表示您已同意接受该等服务规则条款，并将受其约束。</span></strong>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >1.5 您理解并同意，经济运行平台有权基于业务功能、使用规则、法律法规及监管要求、更好的提供服务、经营策略等原因随时修改和（或）补充</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >本协议及各项规则，修改后的协议及规则自公布后即时生效。如果您不同意经济运行平台对本协议及相关规则所做的修改，您应立即停止使用经济运行平台的服务；如您继续使用的，则将视为您已接受了修改后的条款，当您与经济运行平台发生争议时，应以最新的协议条款为准。</span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >1</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >.</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >6</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >&nbsp;<span
                  >经济运行平台始终贯彻向用户提供合法合规服务的原则，依据相关法律、法规和监管要求，经济运行平台不支持</span
                ></span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >在中华人民共和国</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >境外（为本协议表述之目的，境外包含香港特别行政区、澳门特别行政区及台湾地区）</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >的</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                ><span>主体注册、登录或访问以及使用境外</span
                ><span>IP登录或访问。</span></span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">二、</span
            ><span style="font-family: 黑体; font-size: 10.5pt">账户注册与管理</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">2.1
              您知悉并承诺，您具有完全民事权利能力，或具备与注册经济运行平台账户和使用经济运行平台</span
            ><span style="font-family: 黑体; font-size: 10.5pt">服务相适应的年龄、智力要求，或您虽不具有完全民事行为能力但已经过您的父母或其他法定监护人同意并由您的父母或其他法定监护人代理注册、使用</span
            ><span style="font-family: 黑体; font-size: 10.5pt">经济运行平台服务。若您不具备前述相适应的民事行为能力即进行账号注册的，您及您的父母或其他法定监护人应依照法律规定承担因此而导致的一切后果。在该等情形下，经济运行平台有权注销您的账号或停止向您提供服务。您一旦注册成为经济运行平台用户，即视为您授权经济运行平台为您获取、汇编、提供相关企业公共数据。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">2.2</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >您进行经济运行平台账户注册时（包括您通过第三方账号授权登录），应遵循诚实信用、合法善意的原则，按照经济运行平台平台注册页面的提示填写注册信息。您使用、填写的账户资料必须符合</span
            ><span style="font-family: 黑体; font-size: 10.5pt">诚信、真实、合法、有效等原则，</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >不得未经相关权利人授权以他人或其他组织机构的名义注册经济运行平台账号，</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >不得使用引人误解的信息注册账号（包括但不限于让人误认为该账号与其他个人或机构组织存在关联关系的名称、头像或简介等），亦不得使用</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >带有非法、淫秽、污辱或人身攻击含义</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >的信息注册账户。</span
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >否则，经济运行平台有权暂停或终止您的注册，或拒绝您在目前或将来对经济运行平台服务的使用。如果因您所提供的注册资料不合法、不真实、不准确</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >、未及时更新或存在其他不符合本协议规定的情形从而导致相关法律责任或不利后果的，将由您自行承担。</span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >2.3当您注册成为经济运行平台用户后，您注册使用的手机号码即为您的用户账号，经济运行平台将通过向您提供的手机号码发送验证码来确认您的用户身份，</span
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >同一个手机号只能注册一个账号。</span
              ></strong
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >为保证您账号安全，如手机号码发生变动，请及时修改，</span
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >如因您自身原因未及时修改手机号而造成的账号被盗、个人信息泄露等损失，经济运行平台将不承担相关责任，但会尽力协助您处理以减少因此给您造成的影响。</span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >2.4账号注册成功后，如您的注册资料有更新的，您可以随时登陆经济运行平台账户信息页面更改基本信息。</span
            ><span style="font-family: 黑体; font-size: 10.5pt">同时，您亦可以自主选择完善您的其他用户资料，以提升您的用户服务体验。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >2.5除通过第三方账号授权登录外，您仅可通过我们的官方渠道注册成为我们的注册用户。您不得通过包括但不限于购买、租用、借用、分享、利用或破坏我们的注册系统及/或其他未经我们许可的方式获得</span
            ><span style="font-family: 黑体; font-size: 10.5pt">经济运行平台账号。</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >2.6经济运行平台将对您所提交的注册信息予以保护，对于您所提交的注册信息中涉及个人信息的内容，经济运行平台将严格按照本协议及《隐私权政策》的规定进行收集、处理、使用和共享安排。</span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">三、账户使用与注销</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >3.1您账户注册成功后，即可凭该账号登录经济运行平台，并按规定使用经济运行平台的各项服务。您未完成经济运行平台的注册程序的，经济运行平台仍允许您访问经济运行平台并使用有限的服务。</span
            >
          </p>
          
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">3.2您登录经济运行平台账户</span
            ><span style="font-family: 黑体; font-size: 10.5pt">使用经济运行平台服务时，</span>
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >不得存在非人为的连续、频繁的查询操作行为，或通过机器抓取、复制、镜像等方式不合理地、大批量地获取</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >经济运行平台平台的信息、资讯、数据，否则经济运行平台有权</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                ><span>对您的账号封号且无需向您承担任何责任。若同一个</span
                ><span>IP地址内多个账号存在多次上述行为，</span></span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >经济运行平台将有权自动封闭该IP地址且无需向您承担任何责任。</span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >3.3为保证相关账号安全，未经经济运行平台书面同意，</span
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >您不得将账号转让、出售、出租、出借或分享给他人使用，否则，因此</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >侵犯或可能侵犯经济运行平台或第三方的合法权益的，</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >应由您承担相关赔偿责任，且经济运行平台</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >有权不经通知而限制、冻结、注销您的账户，暂停或终止向您提供本协议项下的部分或全部服务，且无需为此承担任何责任。经济运行平台会基于一定标准判断您的账号是否存在前述违规使用情形，比如同一账号近期登录使用设备数量是否异常，同一账号单日查询次数是否异常，是否存在其他足以被认定为违规使用的具体行为。</span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >3.4您对您注册账号的安全以及您账号进行的所有活动和事件（包括但不限于在线签署任何协议，上传、发布、输入任何内容）所产生的一切后果负法律责任，通过您的账号所发生的各项行为将视为您本人的真实意思表示。您应该妥善、</span
            ><span style="font-family: 黑体; font-size: 10.5pt">正确的保管、使用、维护您的账号、密码，对您的账号和密码采取必要和有效的保密措施，</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >否则因您个人原因导致的账号、密码或其他有效识别信息泄露、丢失或账号和密码被窃取、篡改等导致的一切损失</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >或损害，经济运行平台不承担任何责任。若您不慎遗失密码，可以通过注册或绑定的手机号获取验证码，进行密码重置</span
            ><span style="font-family: 黑体; font-size: 10.5pt">来</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >找回密码。若您发现任何非法盗用账号、密码或</span
            ><span style="font-family: 黑体; font-size: 10.5pt">账户</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >存在安全漏洞的情况，请立即通知</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >经济运行平台，您理解经济运行平台对您的请求采取行动需要合理时间，经济运行平台对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">3.5为保护账号安全，防止账号被盗等情况发生，</span
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >经济运行平台可能会不时或定期采用一种或多种方式对注册用户进行用户身份验证（如短信验证等），如用户</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                ><span
                  >未成功通过验证，我们有合理理由怀疑该账号出现被盗等不安全情况，并可能视情节严重情况而单方决定是否中止或终止向该账号继续提供我们的产品及</span
                ><span
                  >/或服务及/或采取进一步措施。您同意，我们采取前述措施是为了保护用户账号安全，我们无需因此承担任何责任。</span
                ></span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">3.6经济运行平台为您提供账号注销功能。您可以通过以下方式申请注销您的账号：</span
            >
          </p>
          
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              ><span>（</span
              ><span
                >1）登录网页端，通过“我的主页-个人信息-帐号信息-注销账号”提交账号注销申请；</span
              ></span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              ><span>（</span
              ><span
                >2）登录经济运行平台小程序，通过“我的-账号设置-注销账号”提交账号注销申请；</span
              ></span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              ><span>（</span
              ><span
                >3）您可以向经济运行平台客服邮箱发送电子邮件，提交您注销账号的需求，我们会协助您申请注销您的账号。</span
              ></span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息或对您的个人信息进行匿名化处理，但法律法规另有规定的除外。</span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >四、经济运行平台平台服务</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >4.1在遵守法律法规及本协议规定前提下，您注册并登录经济运行平台账户后，可根据自身需求及具体服务规则选择使用经济运行平台提供的各类服务。</span
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >您理解并知悉，经济运行平台的部分产品或服务是以收费方式提供，您需要购买会员资格或充值、付费后才能使用该项服务，请您在具体使用相关服务前仔细确认相关规则、订单信息，您提交订单信息并成功支付相应价款后，您与经济运行平台关于相关产品或服务的具体协议条款即生效。</span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >4.2经济运行平台的服务内容包括但不限于：</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              margin-left: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">（1）</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >使用经济运行平台进行免费信息查询服务（如地区经济查询、指标查询，具体以经济运行平台展示为准）；</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              margin-left: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（2）</span>
            <span style="font-family: 黑体; font-size: 10.5pt"
            >通过购买会员、付费等方式使用会员服务（如查看产经智联、研报及下载导出等）；
            </span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              margin-left: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">（3）</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >其他在本协议项下约定的服务，或经济运行平台所不时推出的新功能或新服务。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >4.3经济运行平台将积极维护平台的正常运行，提高所登载信息的准确性、全面性，改进技术，提升您的服务体验，并对您就平台服务等相关问题所反映的客观情况和建设性建议，进行研究并予以改进。如您在使用经济运行平台服务的过程中发现平台中的相关服务或信息违反法律规定，您可及时向经济运行平台举报或投诉，经济运行平台将在收到您的举报或投诉时采取相应的核实与处理措施。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >4.4您理解并同意，经济运行平台系根据相关法律法规，对已公开的信息进行如实展示，并不主动编辑或修改。受限于现有的技术水平、信息来源网站更新不同步等因素，经济运行平台展示的现有信息，并不视为经济运行平台对其内容的准确性、真实性、完整性、时效性的确认或担保。同时，经济运行平台平台仅就您提交的查询请求做识别应答，您查询到的内容与被提交查询的主体是否相符需要您自行掌握和判断，经济运行平台平台返回的查询数据结果亦不构成经济运行平台对任何人之明示或暗示的观点或保证，请您在依据经济运行平台服务提供的相关信息作出判断或决策前，核实此类信息的完整性或准确性，并自行承担使用后果。经济运行平台承诺将不断提升自身技术水平，为您提供更高品质的服务。</span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >4.5您理解并同意，为向您提供更为细致、贴心的服务，经济运行平台将向您发送与经济运行平台网站管理、服务规则更新等相关的系统公告、电子邮件、站内信和/或手机短消息，其方式和范围可不经向您特别通知而变更，该类通知内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</span
              ></strong
            >
          </p>
          <p style="
              font-family: Calibri;
              font-size: 12pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >4.6您知悉、理解并同意，经济运行平台服务过程中，可能涉及收集、存储、使用、共享和保护用户个人信息。在您使用经济运行平台提供的服务时，您同意经济运行平台依据</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >本协议及</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >《隐私权政策》的规定执行相关个人信息的收集、使用和共享。</span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">五</span
            ><span style="font-family: 黑体; font-size: 10.5pt">、</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >用户行为规范与责任承担</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >5.1您知悉并承诺，在使用经济运行平台服务的过程中，需遵守以下规定：</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              ><span>（</span
              ><span
                >1）遵守国家及地方性法律、法规等规范文件，不从事任何违反法律法规规定、违背社会公共利益或公共道德、损害经济运行平台平台及他人合法利益的行为。</span
              ></span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">（2）遵守与经济运行平台平台服务有关的协议、规定，不违反本协议、具体服务规则。</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">（3）不利用或借用经济运行平台平台进行任何可能对互联网或移动网络正常运转造成不利影响、危害信息网络安全活动的行为。包括但不限于：</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              margin-left: 21pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">a、未经书面允许，进入经济运行平台或其他计算机信息网络或者使用计算机信息网络资源的；&nbsp;</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              margin-left: 21pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">b、未经书面允许，对经济运行平台或其他计算机信息网络功能进行删除、修改或者增加的；&nbsp;</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              margin-left: 21pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt"
              >c、未经书面允许，进入经济运行平台或其他计算机信息网络，对存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              margin-left: 21pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >d、制作、传播计算机病毒（包括木马）等破坏性程序，盗取其他用户账户、密码、隐私、操控他人电脑的；&nbsp;</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              margin-left: 21pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">e、</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >通过爬虫工具、机器人软件或其他自动方式访问或尝试访问我们的产品或服务、获取有关数据信息</span
            ><span style="font-family: 黑体; font-size: 10.5pt">的</span
            ><span style="font-family: 黑体; font-size: 10.5pt">；</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              ><span>（</span
              ><span
                >4）不得利用经济运行平台平台上传、展示或传播任何国家或法律禁止发布的、危害国家安全的、泄露国家秘密的、颠覆国家政权的、破坏国家统一的、违背社会公共利益或公共道德/公序良俗的、危害网络安全的、涉嫌侵犯他人合法权益的、虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料。</span
              ></span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              ><span>（</span
              ><span
                >5）不得进行任何操作或使用任何装置、插件、外挂、第三方工具等，干预或试图干预经济运行平台平台的正常运作、产品或服务完整性、准确性或在经济运行平台平台上进行任何活动。</span
              ></span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              ><span>（</span
              ><span
                >6）未经书面允许，利用经济运行平台从事广告发布、变相广告、口碑营销、产品销售、传销等行为。</span
              ></span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              ><span>（</span
              ><span
                >7）对经济运行平台相关服务任何部分或本服务之使用或获得，进行出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等或用于任何其它商业目的。</span
              ></span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              ><span>（</span
              ><span>8）提交、发布虚假信息，或冒充、利用他人名义。</span></span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              ><span>（</span><span>9）其他违反法律法规规定、侵犯</span></span
            ><span style="font-family: 黑体; font-size: 10.5pt">经济运行平台或</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >其他第三方合法权益的行为。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >5.2您理解并同意，经济运行平台为您提供的是一项不可转让、可撤销及非排他性的服务，除另有书面约定，您仅可以为非商业目的使用我们的产品及服务，不得以营利为目的将您在使用经济运行平台服务时获得的服务信息或该服务自身向第三方出售或以其他形式提供，您不得利用经济运行平台提供的数据及服务信息从事任何违反法律、法规或其他监管政策的行为。</span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >5.3依照监管要求，您应于</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >中华人民共和国</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >境内使用经济运行平台服务，您通过经济运行平台获取的任何数据信息，仅应当在中华人民共和国</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >境内</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                ><span>存储及</span><span>/或使用，不得使用境外</span></span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >IP</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >登录或访问，不得将通过经济运行平台获得的数据信息传输至境外，或以任何方式允许任何境外的机构、组织或者个人对上述数据进行查询、调取、下载、导出。否则经济运行平台有权停止向您提供服务，</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >且</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >无需退还</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >任何</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >费用（如有），并追究法律责任。</span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >5.4您理解并同意，为了确保您按照法律法规及本协议、具体服务规则规定使用经济运行平台的服务，经济运行平台或相关国家监管机构有权随时对您使用经济运行平台服务的情况进行监督和检查。</span
              ></strong
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >经济运行平台将自行或是根据国家机构要求，对您享有的服务开展风险评估、检测、检查和审计，如涉及隐私信息的，经济运行平台会采取合理的保护措施，您应当予以配合。如您拒绝的，经济运行平台有权停止向您提供服务，直至您配合并且经济运行平台确认您使用经济运行平台服务行为符合法律及本协议规定。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >5.5如您在使用经济运行平台服务的过程中发现其他用户上传违法、侵权等内容的，可以通过投诉指引联系经济运行平台客服人员，相关人员会尽快核实并进行处理。如您认为上述方法无法解决问题、或您觉得有必要向司法行政机关寻求帮助的，可以向相关机关反馈，经济运行平台将依法配合司法机关的调查取证工作。</span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">六</span
            ><span style="font-family: 黑体; font-size: 10.5pt">、</span
            ><span style="font-family: 黑体; font-size: 10.5pt">知识产权</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >6.1除非经济运行平台另行声明，经济运行平台平台的所有产品、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图表、音频、视频、色彩、版面设计、电子文档）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密）及</span
            ><span style="font-family: 黑体; font-size: 10.5pt">其他</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >相关权利，均归经济运行平台所有。未经经济运行平台书面授权许可，任何人不得对软件进行反向工程、反向编译或反汇编，不得擅自抓取、转载、</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >复制、出版、发行、公开展示、编码、翻译、传输、</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >散布、镜像经济运行平台平台的数据、信息和资料或以其他软件程序自动获得经济运行平台数据，不得将获取的信息数据提供给第三者使用，否则，经济运行平台将依法追究您的法律责任。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >6.2</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >您理解并同意，对于您通过经济运行平台</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >上传、发布、传输或传播</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >到经济运行平台上可公开获取的任何内容（包括但不限于图像、照片、动画、录像、录音、音乐、文字等）均由您原创或已获合法授权，您的上传、提交、存储或发布行为不会侵犯他人的知识产权或其他合法权益，否则，经济运行平台有权</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >删除或者采取其他必要措施予以制止（</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >包括但不限于屏蔽</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                ><span>及</span><span>/或终止</span></span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >您账户的使用</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >）</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >；同时，对于您上传到经济运行平台平台上可公开获取内容，您授权经济运行平台在全世界范围内具有免费的、永久性的、不可撤销的、非独家的使用和完全再许可的权利，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。</span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">6.3</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >&nbsp;<span
                >为持续改善并为用户提供更好的服务，如果您是企业用户，您同意</span
              ></span
            ><span style="font-family: 黑体; font-size: 10.5pt">经济运行平台</span
            ><span style="font-family: 黑体; font-size: 10.5pt">在</span
            ><span style="font-family: 黑体; font-size: 10.5pt">经济运行平台</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >系列产品以及</span
            ><span style="font-family: 黑体; font-size: 10.5pt">经济运行平台</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              ><span>举办的活动及</span
              ><span
                >/或其他宣传推广中使用您的名称、字号、商标等信息或标识。</span
              ></span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">6.4</span
            ><span style="font-family: 黑体; font-size: 10.5pt">如您认为</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >经济运行平台或经济运行平台平台上的内容</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >对您的知识产权产生了不当影响，您可通过</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >经济运行平台指定渠道</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >提交维权材料，</span
            ><span style="font-family: 黑体; font-size: 10.5pt">经济运行平台</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >会根据您的要求及材料进行合理性、合法性判断，并及时反馈。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">七</span
            ><span style="font-family: 黑体; font-size: 10.5pt">、</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >未成年人保护</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >7.1
              若您是未满18周岁的未成年人，应经过监护人的同意并在其监护、指导下，认真阅读本协议后，方可使用经济运行平台服务。已满18周岁的成年人因任何原因不具备完全民事行为能力的，参照适用本协议未成年人保护条款的相关约定。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >7.2经济运行平台重视对未成年人个人信息的保护，您在填写个人信息时，请加强个人保护意识并谨慎对待，并在监护人指导</span
            ><span style="font-family: 黑体; font-size: 10.5pt">下</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >正确使用经济运行平台服务。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >7.3未成年人用户应在其监护人的监督指导下，在合理范围内正确使用网络，养成良好的上网习惯，避免沉迷虚拟的网络空间。未成年人用户在使用经济运行平台服务时必须遵守《全国青少年网络文明公约》，提高安全意识，加强自我保护。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >7.4若未成年用户存在违反法律法规、监管部门规范性文件规定或本协议及其他应补充协议的情形，该未成年人用户及其监护人应依照法律规定承担因此引起的全部法律责任。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >7.5为更好地保护未成年人隐私权益，经济运行平台提醒您慎重发布包含未成年人素材的内容，一经发布，即视为您同意经济运行平台及相关服务展示该未成年人信息、肖像等内容，且允许经济运行平台依据本协议使用、处理该等未成年人相关内容。</span
              ></strong
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">八</span
            ><span style="font-family: 黑体; font-size: 10.5pt">、</span
            ><span style="font-family: 黑体; font-size: 10.5pt">违约责任</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">8</span
            ><span style="font-family: 黑体; font-size: 10.5pt">.1</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >您理解并同意，</span
            ><span style="font-family: 黑体; font-size: 10.5pt">经济运行平台</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >有权根据您的具体</span
            ><span style="font-family: 黑体; font-size: 10.5pt">行为</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >做出独立判断，</span
            ><span style="font-family: 黑体; font-size: 10.5pt">在认为</span
            ><span style="font-family: 黑体; font-size: 10.5pt">您使用</span
            ><span style="font-family: 黑体; font-size: 10.5pt">经济运行平台</span
            ><span style="font-family: 黑体; font-size: 10.5pt">服务过程中</span
            ><span style="font-family: 黑体; font-size: 10.5pt">存在</span
            ><span style="font-family: 黑体; font-size: 10.5pt">违反</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >法律法规或本协议规定</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >的一项或多项</span
            ><span style="font-family: 黑体; font-size: 10.5pt">情形时，可</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >立即暂停或终止对您提供部分或全部服务，</span
            ><span style="font-family: 黑体; font-size: 10.5pt">并对您采取</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >包括冻结、注销、删除帐号，删除或屏蔽相关违规信息、服务评价</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >等具体措施。除此之外，如因您的违规、违约行为导致经济运行平台或其他第三方的权益受到损害的，您需承担全部赔偿责任（包括但不限于财产损害赔偿、名誉损害赔偿以及交通费、律师费、调查取证费、诉讼费、财产保全费、执行费等），并支付不低于您就违规、违约行为所获的不当利益数额的违约金。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">8</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >.2经济运行平台有权依据合理判断，对违反相关法律法规、本协议约定的行为采取适当的行动（包括但不限于向监管机关举报、提起诉讼等），并依据法律法规保存有关信息作为您的违规证据，您应独自承担由此而产生的一切法律责任。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">8</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >.3为了保证用户能更好地使用经济运行平台服务，您出现前述争议或涉及纠纷时，经济运行平台有权不经事先通知或获得您的同意将争议方的主体信息、联系方式、投诉相关内容等系统内的相关记录和必要信息提供给相关当事方或相关部门。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">8</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >.4您知悉并同意，因您违反有关法律法规、监管政策及/或本协议内容，导致第三方的任何索赔或权利主张，您应当独立承担责任，因此经济运行平台遭受任何损失的，您应当一并赔偿。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">九、协议的终止</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">9.1本协议于以下任一情形下终止：</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              ><span>（1）经济运行平台依据本法律法规或本协议约定，收回、注销用户账号或停止对您提供服务；</span
              ></span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              ><span>（2）在满足经济运行平台公示的账号注销条件时，您通过经济运行平台自助服务或经济运行平台客服注销账号，本服务协议于账号注销之日终止。</span
              ></span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              ><span>（3）经济运行平台有权根据自身商业安排经过合理的提前通知终止全部平台服务，本服务协议于经济运行平台平台全部服务依法定程序及方式终止之日终止。</span
              ></span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">9.2本协议终止后，经济运行平台将无法继续向您提供任何服务或履行任何其他义务，包括但不限于为您保留或向您披露您原账号中的任何信息，向您或第三方转发任何未曾阅读或发送过的信息等。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">9.3本协议的终止不影响相关知识产权条款以及其他根据其内容应继续有效的条款的有效性，也不影响本协议终止前各方的相关权利和义务，包括但不限于守约方依据本服务协议向违约方追究</span
            ><span style="font-family: 黑体; font-size: 10.5pt">过往</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >的违约责任。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">十、通知与送达</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >10.1您知悉并认可，经济运行平台将通过系统公告、站内信、弹窗或向您注册时所提交的电子邮箱、手机号码发送电子信息等方式，向您通告重要信息。</span
              ></strong
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >该通告于刊登完成或发送成功之日即视为已送达收件人。该送达方式同样可适用于相关仲裁或司法程序（含起诉、审理、执行等各阶段）。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">10.2您应当保证所提供的联系方式是准确、有效的，并进行适时更新，如因提供的联系方式不准确或怠于更新等不可归责于经济运行平台的原因，导致相关通知、文件、文书无法送达或及时送达，您将自行承担由此可能产生的法律后果。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">十一、其他</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">11.1本协议的订立、执行和解释及争议的解决均应适用中华人民共和国大陆地区法律法规，并排除一切冲突法规的适用。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">11.2如发生经济运行平台平台服务条款与中国现行法律相抵触时，则这些条款将完全按法律规定重新解释，而其它合法条款则依旧保持对您产生法律效力和影响。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">11.3如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，各方均可向本协议签订地有管辖权的人民法院提起诉讼。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >11.4如果您采用纸质合同、订单或其他纸质文件（以下统称“</span
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >纸质</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >合同</span
              ></strong
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >”）的形式购买经济运行平台服务，您亦获得经济运行平台用户身份，享有本协议项下的权利，并应履行本协议项下的义务。</span
            ><span style="font-family: 黑体; font-size: 10.5pt">纸质</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >合同未尽事宜，以本协议约定为准。</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">十二、</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >如何联系我们</span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">12.1</span
            ><span style="font-family: 黑体; font-size: 10.5pt">如对本</span
            ><span style="font-family: 黑体; font-size: 10.5pt">协议</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              ><span
                >内容有任何疑问、意见或建议，您可以通过以下方式与我们联系，我们将在收到您的需求后，在</span
              ><span>15个工作日内予以回复：</span></span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">（</span
            ><span style="font-family: 黑体; font-size: 10.5pt">1</span
            ><span style="font-family: 黑体; font-size: 10.5pt">）</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >经济运行平台客服邮箱：</span
            ><a href="mailto:【market@chinaecono.com】；"
              ><u
                ><span
                  class="15"
                  style="
                    font-family: 黑体;
                    color: #333333;
                    font-size: 10.5pt;
                    background-image: initial;
                    background-position: initial;
                    background-size: initial;
                    background-repeat: initial;
                    background-attachment: initial;
                    background-origin: initial;
                    background-clip: initial;
                  "
                  ><span>【market@chinaecono.com】</span></span
                ></u
              ></a
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">（</span
            ><span style="font-family: 黑体; font-size: 10.5pt">2</span
            ><span style="font-family: 黑体; font-size: 10.5pt">）</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              ><span>经济运行平台电话：【15324076890】</span></span
            >
          </p>
          <p style="
              text-align: left;
              font-family: Calibri;
              font-size: 12pt;
              font-weight: 400;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">（3）经济运行平台</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >联系地址：北京市海淀区中关村大街1号17层M22号。</span
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              font-weight: 400;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <FooterNew></FooterNew>
</template>
<script>
import HeaderFirst from '@/components/HeaderFirst.vue'
import HelpMenu from '@/components/HelpMenu.vue'
import FooterNew from '@/components/FooterNew.vue'
//import { ref } from '@vue/reactivity'
export default {
  components: {
    HeaderFirst,
    HelpMenu,
    FooterNew,
  },
  setup() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.page-col-sm {
  position: relative;
  background-color: #fff;
  min-height: 1px;
  float: left;
  width: 200px;
  min-height: 650px;
}
.page-col-lg {
  width: 1048px;
  position: relative;
  overflow: hidden;
}
.npanel {
  border-radius: 4px;
  border: none;
  min-height: 650px;
  background: #fff;
  box-shadow: none;
  border: solid 1px #eee;
  margin-bottom: 15px;
  margin-left: 15px;
}
.npanel-heading {
  color: #333;
  border-bottom: none;
  h4 {
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
    display: inline-block;
    line-height: 50px;
    margin-left: 15px;
    font-size: 15px;
  }
}
.npanel-body {
  padding: 20px 15px;
  position: relative;
  &:before {
    content: ' ';
    position: absolute;
    height: 1px;
    left: 15px;
    right: 15px;
    top: -1px;
    background: $bordercolor;
    display: block;
    z-index: 2;
  }
  p {
    margin: 0 0 10px;
    line-height: 25px;
  }
}
</style>